import React, { useCallback }  from 'react'
import LightGallery, { lgVideo, Video, VideoProps, VideoContainer  } from '../../utils/lightgallery'

export type VideosProps = { 
  videos: Array<VideoProps> 
  ncol: number
}


export const Videos: React.FC<VideosProps> = ({ videos, ncol, ...props }) => {
  //Load vimeo player to allow current video end when going to next video
  const onInit = useCallback(async () => {
    if (typeof window !== 'undefined' && typeof window.Vimeo === 'undefined') { 
      global.Vimeo = (await import('@vimeo/player'))
      global.Vimeo.Player = global.Vimeo.default
    }
  }, [])

  return (
    <LightGallery       
      onInit={onInit}
      selector=".video_component" 
      licenseKey={process.env.REACT_APP_GALLERY_LICENSE}
      download={false}
      plugins={[ lgVideo]} >
      <VideoContainer ncol={ncol} {...props} >
        {Array.isArray(videos) && videos.map((video, index) => {      
          return (
            <Video title={video.title} videoSource={video.videoSource} thumbnail={video.thumbnail} key={index} ></Video>
          )
        })}
      </VideoContainer>
    </LightGallery>)
}