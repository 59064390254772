import React, { useState, useEffect, useRef, RefObject } from 'react'
import axios, { AxiosResponse } from 'axios'
import Image from 'next/image'
import { useTheme } from '@material-ui/core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { StyledVideo, StyledImage, StyledOverlay, StyledCircleIcon, StyledPlayIcon, StyledTitle, StyledTitleContainer  } from './StyledVideo'

const isVideo = (src:string) => {
  if(src) {
    const youtube = src.match(/\/\/(?:www\.)?youtu(?:\.be|be\.com|be-nocookie\.com)\/(?:watch\?v=|embed\/)?([\w%\\-]+)/i)

    const vimeo = src.match(/\/\/(?:www\.)?(?:player\.)?vimeo.com\/(?:video\/)?([\w\\-]+)/i)

    if (youtube) {
      return {
        youtube: youtube,
      }
    } else if (vimeo) {
      return {
        vimeo: vimeo,
      }
    }
  }

  return null
}

const getDimensions = (ref: RefObject<HTMLElement>) => ({
  width: ref.current?ref.current.offsetWidth:0,
  //Expect aspect ratio 16:9
  height: ref.current?ref.current?.offsetWidth*9/16:0,
})

export const getContainerDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    
  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(ref))
    }
    
    if (ref.current) {
      setDimensions(getDimensions(ref))
    }
    
    window.addEventListener('resize', handleResize)
    
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])
    
  return dimensions
}  
  
export type VideoProps = {
    title?: string
    thumbnail?: string
    videoSource: string
}

export const Video: React.FC<VideoProps> = ({ title, thumbnail, videoSource, ...props }) => {
  const useDimensionRef = useRef()
  const [thumb, setThumbnail] = useState(null)
  const  theme = useTheme()
  const { width, height } = getContainerDimensions(useDimensionRef)

  const createThumbnail = (videoSource: string) => {
    const video = isVideo(videoSource)
  
    if(video.youtube){
      setThumbnail(`https://img.youtube.com/vi/${video.youtube[1]}/maxresdefault.jpg`)
    } else if(video.vimeo){
      try{
        axios.get(`https://vimeo.com/api/v2/video/${video.vimeo[1]}.json`)
          .then((response: AxiosResponse) => {
            setThumbnail(response.data[0]['thumbnail_large'])
          })
      } catch {
        setThumbnail(null)
      }
    }
  }

  useEffect(() => { 
    if(thumbnail){
      setThumbnail(thumbnail)
    }else{
      createThumbnail(videoSource)
    }
  }, [thumb, thumbnail, videoSource])

  return(
    <StyledVideo  {...props} ref={useDimensionRef}>      
      <StyledImage className="video_component" data-src={videoSource} data-poster={thumb} data-sub-html={title} style={{ height: height, width: width }} >
        {thumb && (<Image src={thumb} alt={title} layout="fill" objectFit="cover" objectPosition="top right" />)}    
        <StyledOverlay>
          <StyledCircleIcon  htmlColor={theme.palette.primary.main} icon={fas['faCircle']}  />
          <StyledPlayIcon  htmlColor={theme.palette.common.white} icon={fas['faPlay']} />
        </StyledOverlay>
      </StyledImage>
      <StyledTitleContainer>
        {title && (<StyledTitle variant="h3">{title}</StyledTitle>)}
      </StyledTitleContainer>      
    </StyledVideo>
  )
}
