import React from 'react'
import parse, { attributesToProps, HTMLReactParserOptions, domToReact } from 'html-react-parser'
import styled from 'styled-components'
import { Element } from 'domhandler/lib/node'
import { Typography } from '@material-ui/core'
import { Table } from '../components/table/Table'
import { Button } from '../components/button/button'
import { Videos } from '../components/video/Videos'
import Image from 'next/image'
import getConfig from 'next/config'

const { images }= getConfig()

const StyledVideo = styled(Videos)`
  height: ${props => props.theme.typography.pxToRem(400)};
  width: ${props => props.theme.typography.pxToRem(400)};
`

const ImageContainer = styled.div`
  > div {
    height: ${props => props.theme.typography.pxToRem(400)};
    width: ${props => props.theme.typography.pxToRem(400)};
  }
`

const StyledImage = styled.img`
  height: ${props => props.theme.typography.pxToRem(400)};
  width: ${props => props.theme.typography.pxToRem(400)};
`

const options: HTMLReactParserOptions = {  
  replace: domNode => {
    const typedDomNode = domNode as Element

    if ( typedDomNode.attribs) {
      switch (typedDomNode.name) {
      case 'h2':
      case 'h3':
      case 'h4': 
      case 'h5':
        return  ( 
          <Typography {...attributesToProps(typedDomNode.attribs)} variant={typedDomNode.name}>
            {typedDomNode.children && domToReact(typedDomNode.children, options)}
          </Typography>
        )
      
      case 'table': {
        return  ( 
          <Table {...attributesToProps(typedDomNode.attribs)}>
            {typedDomNode.children && domToReact(typedDomNode.children, options)}
          </Table>
        )
      }
        
      case 'iframe': {
        const videoSrc = attributesToProps(typedDomNode.attribs)
        const videoSourceArray = [{ videoSource: videoSrc.src }]

        return (
          <StyledVideo videos={videoSourceArray} ncol={0}>
            {typedDomNode.children && domToReact(typedDomNode.children, options)}
          </StyledVideo>
        )
      }

      case 'button': {
        if (typedDomNode.attribs.class === 'btn-btn-black') {
          return (
            <Button primary buttonColor="black" {...attributesToProps(typedDomNode.attribs)}>{typedDomNode.children && domToReact(typedDomNode.children, options)} </Button>
          )
        }

        if (typedDomNode.attribs.class === 'btn-btn-orange') {
          return (
            <Button primary buttonColor="orange" {...attributesToProps(typedDomNode.attribs)}>{typedDomNode.children && domToReact(typedDomNode.children, options)} </Button>
          )
        }

        if (typedDomNode.attribs.class === 'btn-btn-green') {
          return (
            <Button primary buttonColor="green" {...attributesToProps(typedDomNode.attribs)}>{typedDomNode.children && domToReact(typedDomNode.children, options)} </Button>
          )
        }

        return null
      }

      case 'img': {
        const imageData = (attributesToProps(typedDomNode.attribs))
        const imageSrc = imageData.src.replace('localhost:8000', 'nginx:80')
        const imageAlt = imageData.alt

        const allowedDomains = images.domains

        const domainMatch = allowedDomains.some(domain => imageSrc.includes(domain))

        if (domainMatch) {
          return(
            <ImageContainer {...attributesToProps(typedDomNode.attribs)}>
              <Image src={imageSrc} alt={imageAlt} layout="fill"/>
            </ImageContainer>
          )
        }

        return  (
          <StyledImage src={imageSrc} alt={imageAlt}/>
        )
      }
      }
    }

    return null
  },
}

export const parseHtml = ( html: string) => {
  return <>{parse(html, options)}</>
}

export const html = parseHtml